<template>
  <div class="myMaintenanceReport">
    <div class="myMaintenanceReport-content">
      <div class="item">
        <div class="item-l">设备名称</div>
        <div class="item-r">
          <v-input
            readonly
            v-model="formList.spaceId"
            inputAlign="right"
            placeholder="请输入设备名称"
          ></v-input>
        </div>
      </div>
      <div class="explain">
        <div class="label">损坏说明</div>
        <div class="explain-text">
          <div class="text-area">
            <v-input
              v-model="formList.malfunctionDescription"
              :maxlength="200"
              showWordLimit
              placeholder="请输入，200字以内..."
              type="textarea"
            ></v-input>
          </div>
          <div class="upload">
            <!-- <updateFile
              :disabled="false"
              v-model="fileList"
              :maxCount="6"
            ></updateFile> -->

            <!-- <v-upload
              :imgUrl.sync="formList.malfunctionPic"
              :activityPicture.sync="formList.malfunctionPic"
              class="oItem-b_upload"
              :maxCount="6"
              ref="load"
            ></v-upload> -->
          </div>
        </div>
      </div>
      <!-- <div class="tips">
        1.图片请保存清晰<br />
        2.图片应能反映事发
      </div> -->
      <!-- <div class="address">
        <span>{{ place ? place : "报修地点" }}</span>
        <span @click="handelSelect"> 请选择 </span>
      </div> -->
    </div>
    <div class="footer">
      <div @click="handelClickSubmit">提交</div>
    </div>
  </div>
</template>

<script>
// import updateFile from "./components/updateFile";
import { submitWorkInfo, getServiceNum } from "./api.js";
export default {
  name: "myMaintenanceReport",
  // components: {
  //   updateFile,
  // },
  data() {
    return {
      fileList:
        "https://cgzc-dev.zhuneng.cn/data/minio/leo/upload/20231105/通用扫描.pdf,https://cgzc-dev.zhuneng.cn/data/minio/leo/upload/20231105/通用扫描.pdf",
      place: "",
      formList: {
        serviceNumber: "",
        equipmentNumber: "ef812d6f250244e290962060ab90b424",
        malfunctionDescription: "", //故障描述
        spaceId: "P1C2A2V1", //故障地点
        applyPeople:'报修人测试',
        applyPhone:'18516155068',
        optUser: "1234566",
        workSource: 2,
      },
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
  },
  created() {
    this.getServiceNum();
    console.log(JSON.parse(sessionStorage.getItem("formList")));
    if (JSON.parse(sessionStorage.getItem("formList"))) {
      const res = JSON.parse(sessionStorage.getItem("formList"));
      this.formList = res;
    }
    // this.getSpaceTreeList();
  },
  mounted() {
    const { place, code } = this.$route.query;
    if (place != undefined) {
      this.place = place;
      this.formList.spaceId = code;
      sessionStorage.removeItem("formList");
    }
  },
  methods: {
    getServiceNum() {
      this.$axios.get(getServiceNum).then((res) => {
        if (res.code == 200) {
          this.formList.serviceNumber = res.data;
        }
      });
    },
    handelClickSubmit() {
      this.$axios.post(submitWorkInfo, this.formList).then((res) => {
        if (res.code == 200) {
          this.$router.push({
            name: "maintenanceReport",
          });
          this.$toast({ message: res.msg, duration: 2000 });
        }
      });
    },
    handelSelect() {
      sessionStorage.setItem("formList", JSON.stringify(this.formList));
      this.$router.push({
        name: "maintenanceReportEditPlace",
        query: {
          path: this.$route.name,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.myMaintenanceReport {
  width: 100%;
  height: 100%;
  background: #fafafa;
  padding-top: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .myMaintenanceReport-content {
    width: 100%;
    background-color: #fff;
    padding: 44px 36px 54px 38px;

    box-sizing: border-box;
    .item {
      display: flex;
      align-items: center;
      .item-l {
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 40px;
      }
      .item-r {
        flex: 1;
      }
    }
    .explain {
      margin-top: 40px;
      box-sizing: border-box;
      .label {
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 40px;
        margin-bottom: 14px;
      }
      .explain-text {
        padding: 28px 32px 28px 34px;
        box-sizing: border-box;
        width: 100%;
        background: rgba(0, 0, 0, 0.04);
        .text-area {
          box-sizing: border-box;
          margin-bottom: 34px;
          ::v-deep .van-cell {
            padding: 0;
            background-color: transparent;
          }
        }
      }
    }
    .tips {
      margin-top: 24px;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 46px;
    }
    .address {
      margin-top: 10px;
      span {
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        line-height: 46px;
        &:first-child {
          margin-right: 10px;
        }
        &:last-child {
          cursor: pointer;
          display: inline-block;
          color: #ff9b55;
          text-align: center;
          line-height: 50px;
          width: 128px;
          height: 50px;
          background: rgba(254, 68, 22, 0.05);
          border-radius: 6px;
        }
      }
    }
  }
  .footer {
    width: 100%;
    background: #ffffff;
    padding: 48px 36px 48px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    > div {
      text-align: center;
      width: 226px;
      height: 74px;
      line-height: 74px;
      background: #fe4416;
      border-radius: 74px;
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }
  }
}
</style>
